import axios, { type CancelTokenSource } from "axios";
import { useEffect, useRef, useState } from "react";
import { Marker, type LngLatBounds } from "react-map-gl";

import Map, { type ViewState, type MapRef } from "react-map-gl/maplibre";
import { GeolocateControl } from "react-map-gl/maplibre";
import "maplibre-gl/dist/maplibre-gl.css";

export const LOCATION_PERMISSIONS_GRANTED = "granted";
export const LOCATION_PERMISSIONS_DENIED = "denied";

export type Event = {
  eventTypeImage: string;
  approved_at: string;
  approved_by?: string;
  city: string;
  country: string;
  created_at: string;
  deleted_at?: string;
  district?: string;
  distance?: number;
  gift_requested: boolean;
  id: string;
  eventType: string;
  event_type: unknown;
  event_type_id: number;
  event_type_image?: string;
  eventTypeData: any;
  host: string;
  host_name: string;
  host_photo: string;
  hostId: number;
  is_existing: boolean;
  latitude: number;
  locality?: string;
  location: string;
  longitude: number;
  name: string;
  neighborhood?: string;
  phone?: string;
  place?: string;
  region?: string;
  state: string;
  state_code?: string;
  street_address?: string;
  updated_at: string;
  url: string;
  user_id: number;
  welcome_sent: boolean;
  zip?: string;
  hostPhoto?: string;
  start_date?: string;
  startDate?: string;
  end_date?: string;
  endDate?: string;
  sex?: string;
  type_group?: string;
};
export const mapFlyTo = (
  mapRef: React.RefObject<MapRef>,
  latitude: number,
  longitude: number,
  zoom: number = 10,
): void => {
  mapRef.current?.flyTo({
    essential: false,
    center: [longitude, latitude],
    duration: 1000,
    zoom,
  });
};
export type ISearchEventsParams = {
  bounds: LngLatBounds;
  sex: string;
  typeGroup: string;
  types: Array<number>;
  startDate: string;
  endDate: string;
};
const eventAPIQuery = async ({
  bounds,
  sex,
  typeGroup,
  types,
  startDate,
  endDate,
}: ISearchEventsParams): Promise<any> => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  try {
    const source: CancelTokenSource = axios.CancelToken.source();
    const response = await axios.get(
      "https://local.wildatheart.org/api/events/search",
      {
        params: {
          sw_latitude: sw.lat,
          sw_longitude: sw.lng,
          ne_latitude: ne.lat,
          ne_longitude: ne.lng,
          sex,
          types: types.join(","),
          type_group: typeGroup,
          start_date: startDate,
          end_date: endDate,
        },
        cancelToken: source.token,
      },
    );

    if (response.status !== 200) {
      throw new Error("could not fetch from the api");
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

interface SearchParams {
  sex?: string;
  typeGroup?: string;
  types?: Array<string>;
  startDate?: string;
  endDate?: string;
}

export type IMapComponentProps = {
  className?: string;
};

export interface MapComponentProps extends IMapComponentProps {
  markerComponent?: any;
  searchParams?: SearchParams;
}

export const defaultLatLng = {
  latitude: 38.90379,
  longitude: -104.86443,
};

function DefaultMarker(event: Event, index: number) {
  return (
    <Marker
      key={index}
      longitude={event.longitude}
      latitude={event.latitude}
      anchor="bottom"
    >
      <EventMarkerButton event={event} href={event.url} />
    </Marker>
  );
}
export default function EmbeddableMap({
  markerComponent = DefaultMarker,
  searchParams,
}: MapComponentProps) {
  const initialViewState = {
    longitude:
      Number(localStorage.getItem("longitude")) || defaultLatLng.longitude,
    latitude:
      Number(localStorage.getItem("latitude")) || defaultLatLng.latitude,
    zoom: 50,
  };

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapRef>(null);

  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [latitude, setLatitude] = useState(defaultLatLng.latitude);
  const [longitude, setLongitude] = useState(defaultLatLng.longitude);
  const [bounds, setBounds] = useState<LngLatBounds | null>(null);
  const [sex] = useState("");
  const [types] = useState([]);
  const [typeGroup] = useState("");
  const [startDate] = useState("");
  const [endDate] = useState("");
  const [eventsListLoading, setEventsListLoading] = useState(false);
  const [showFilterPanel] = useState(false);
  const [events, setEvents] = useState([]);
  const [openGetUserLocationModal, setOpenGetUserLocationModal] =
    useState(false);

  const mounted = useRef(false);

  const handleViewStateChange = async (viewState: ViewState) => {
    if (!showFilterPanel) {
      const { latitude, longitude } = viewState;
      if (!mapRef.current) return;
      const map = mapRef.current.getMap();
      const newBounds = map.getBounds();
      setBounds(newBounds);
      setLatitude(latitude);
      setLongitude(longitude);
    }
  };

  const getMapBoundsAtZoom = (
    map: maplibregl.Map,
    targetZoom: number,
  ): LngLatBounds | null => {
    // Store current state
    const currentZoom = map.getZoom();

    const currentCenter = map.getCenter();
    // Temporarily move camera
    const transform = map.transform.clone();
    transform.zoom = targetZoom;

    // Get bounds at new zoom
    const bounds = transform.getBounds();

    // Clean up
    transform.zoom = currentZoom;
    transform.center = currentCenter;

    return bounds;
  };

  /**
   * This allows us to zoom out as far needed to get at least 3 events
   */
  const zoomOutToEvents = async () => {
    if (!mapRef.current) return;
    const map = mapRef.current?.getMap();

    let zoom = 10;
    let eventsInResponse = [];
    while (eventsInResponse.length < 3 && zoom > 0) {
      zoom = zoom - 1;
      const bounds = getMapBoundsAtZoom(map, zoom);

      // get events at the current zoom level
      eventsInResponse = await eventAPIQuery({
        sex,
        types,
        startDate,
        endDate,
        typeGroup,
        ...searchParams,
        bounds,
      });
    }

    mapFlyTo(mapRef, latitude, longitude, zoom);
  };

  useEffect(() => {
    const storedLatitude = localStorage.getItem("latitude");
    const storedLongitude = localStorage.getItem("longitude");
    const locationPermissions = localStorage.getItem("locationPermissions");

    if (storedLatitude && storedLongitude) {
      console.log("user has coords in local storage, set them to state");
      setLatitude(Number(storedLatitude));
      setLongitude(Number(storedLongitude));
      setTimeout(() => {
        mapFlyTo(mapRef, Number(storedLatitude), Number(storedLongitude));
      }, 1000);
    }

    if (
      !storedLatitude &&
      !storedLongitude &&
      locationPermissions !== LOCATION_PERMISSIONS_DENIED
    ) {
      console.log(
        "user has not stored location and has not denied location permissions, open modal to get location",
      );
      setOpenGetUserLocationModal(true);
    }

    if (
      !locationPermissions ||
      locationPermissions === LOCATION_PERMISSIONS_DENIED
    ) {
      console.log("location denied, get the default events");
      setLatitude(defaultLatLng.latitude);
      setLongitude(defaultLatLng.longitude);
    }
    zoomOutToEvents();
  }, []);

  useEffect(() => {
    console.log("getting events");
    const getEvents = async () => {
      if (!bounds) return;
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      try {
        // console.log(
        //   `sw_latitude: ${sw.lat}, sw_longitude: ${sw.lng}, ne_latitude: ${ne.lat}, ne_longitude: ${ne.lng}, sex: ${sex}, typeGroup: ${typeGroup}, types: ${types}, startDate: ${startDate}, endDate: ${endDate}`,
        // );

        setEventsListLoading(true);

        const events = await eventAPIQuery({
          sex,
          types,
          startDate,
          endDate,
          typeGroup,
          ...searchParams,
          bounds,
        });
        setEvents(events);
        setEventsListLoading(false);
      } catch (error) {
        console.log(error);
        setEventsListLoading(false);
      }
    };

    getEvents();
  }, [latitude, longitude, sex, types, startDate, endDate]);

  useEffect(() => {
    if (selectedPlace && selectedPlace.center) {
      mapFlyTo(mapRef, selectedPlace.center[1], selectedPlace.center[0]);
    }
  }, [selectedPlace]);

  return (
    <Map
      ref={mapRef}
      initialViewState={initialViewState}
      minZoom={2}
      maxZoom={13}
      maxPitch={0}
      minPitch={0}
      pitchWithRotate={false}
      dragRotate={false}
      touchPitch={false}
      customAttribution={``}
      attributionControl={false}
      onDragEnd={async (e) => handleViewStateChange(e.viewState)}
      onZoomEnd={async (e) => handleViewStateChange(e.viewState)}
      style={{ width: "100%", height: "100%" }}
      mapStyle={`https://api.maptiler.com/maps/streets/style.json?key=sr5EVCtJBfz1IQnASSwn`}
      onLoad={async (e) => {
        const zoom = await zoomOutToEvents();
      }}
    >
      {markerComponent && events.map((event, i) => markerComponent(event, i))}

      <GeolocateControl
        trackUserLocation={true}
        showUserLocation={true}
        showAccuracyCircle={true}
        position="top-left"
      />
    </Map>
  );
}

const generalEventClasses = `w-8 h-8  flex items-center justify-center rounded-full hover:bg-p300 hover:stroke-white shadow-xl hover:shadow-none transition duration-150 ease-in-out`;
const activeEventClasses = "bg-p300 stroke-white";
const inactiveEventClasses = "bg-white stroke-black";

const EventMarkerButton = ({
  event,
  href,
}: {
  event: Event;
  href?: string;
}) => {
  const Tag = href ? "a" : "button";

  return (
    <Tag
      href={href}
      className={`${generalEventClasses} ${inactiveEventClasses}`}
    >
      {event.type_group === fireTypeGroup && <FiresIcon />}
      {event.type_group === retreatTypeGroup && <RetreatsIcon />}
      {event.type_group === experienceTypeGroup && <ExperiencesIcon />}
    </Tag>
  );
};

const experienceTypeGroup = "experience",
  fireTypeGroup = "fire",
  retreatTypeGroup = "retreat";

export const ExperiencesIcon = ({ className = "", size = "w-6 h-6" }) => {
  return (
    <svg
      className={`${className} ${size}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0622 5.31995H22.789C25.6373 5.31995 27.9463 7.62893 27.9463 10.4772V17.4614C27.9463 20.3097 25.6373 22.6186 22.789 22.6186H10.0622C7.21524 22.6186 4.90625 20.3097 4.90625 17.4614V10.4772C4.90625 7.62893 7.21524 5.31995 10.0622 5.31995Z"
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0705 15.1394C18.2435 15.888 17.211 16.5618 16.074 17.02C15.1076 17.4012 14.2969 16.9254 14.1773 15.9714C14.0328 14.5666 14.0365 13.2203 14.1773 11.965C14.308 10.9749 15.1998 10.5539 16.074 10.9213C17.1936 11.3796 18.1974 12.0023 19.0705 12.8019C19.8165 13.4781 19.8339 14.4384 19.0705 15.1394Z"
        strokeWidth="1.92"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const RetreatsIcon = ({ className = "", size = "w-6 h-6" }) => {
  return (
    <svg
      className={`${className} ${size}`}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.86719 11.0214H22.2609"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 3.79504V7.00644"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61719 3.79504V7.00644"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.684 5.33661H9.42129C6.55488 5.33661 4.76562 6.93281 4.76562 9.86674V18.6991C4.76562 21.6795 6.55488 23.3126 9.42129 23.3126H17.6755C20.5504 23.3126 22.3313 21.7079 22.3313 18.773V9.86674C22.3396 6.93281 20.5589 5.33661 17.684 5.33661Z"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 15.5748V18.4908"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61719 15.5748H9.63302V18.4908"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4219 17.2996V17.0316"
        strokeWidth="1.62644"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FiresIcon = ({ className = "", size = "w-6 h-6" }) => {
  return (
    <svg
      className={`${className} ${size}`}
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44531 26.6248L24.3452 18.854"
        strokeWidth="1.88125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5025 16.9308C20.532 16.2658 21.9427 11.6933 20.1891 8.28009C19.2678 6.363 17.7008 5.01701 15.8521 4.05054C16.134 6.22022 15.5837 9.89455 13.5287 9.89455C13.0284 10.1057 12.7086 7.30508 12.7086 6.94265C12.4658 7.11714 11.8825 7.65774 11.6702 7.86763C7.70663 11.6444 11.226 18.0864 16.5025 16.9308Z"
        strokeWidth="1.88125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.383 12.1315C17.383 12.1315 16.7643 13.5837 15.0156 13.3153"
        strokeWidth="1.88125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5199 20.7285L7.44531 18.8554M24.3461 26.6256L19.5089 24.401"
        strokeWidth="1.88125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
