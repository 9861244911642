<script lang="ts">
  import { slide, blur } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import Button from "@elements/Button.svelte";
  let showButtons = true;
  export let typeId: string;
  export let buttonText: string = "Find an eventnear you";

  const useMyLocation = () => {
    localStorage.setItem("locationPermissions", "granted");
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  };

  const successCallback = (position: GeolocationPosition) => {
    console.log("Latitude:", position.coords.latitude);
    console.log("Longitude:", position.coords.longitude);
    localStorage.setItem("latitude", position.coords.latitude.toString());
    localStorage.setItem("longitude", position.coords.longitude.toString());
    showButtons = false;
  };

  const errorCallback = (error: GeolocationPositionError) => {
    console.error("Error getting location:", error.message);
    showButtons = false;
  };
</script>

{#if showButtons}
  <div
    transition:blur={{ amount: 10 }}
    class={`absolute h-full w-full top-0 left-0 z-50 bg-black/70 flex-center `}
  >
    <div
      class={`mt-6 flex flex-col md:flex-row gap-3 items-center h-full justify-center `}
    >
      <Button
        on:click={useMyLocation}
        leftIcon={{ name: "location" }}
        text={buttonText}
      />
      <Button
        href={`https://local.wildatheart.org?types=${typeId}`}
        leftIcon={{ name: "menu" }}
        text="Explore All events"
        variant="secondary"
      />
    </div>
  </div>
{:else}
  <div
    transition:slide={{
      delay: 500,
      duration: 300,
      easing: quintOut,
      axis: "y",
    }}
    class={`absolute w-full bottom-0 left-0 z-50 bg-black/70 flex-center `}
  >
    <div
      class={`my-6 flex flex-col md:flex-row gap-3 items-center h-full justify-center `}
    >
      <Button
        href={`https://local.wildatheart.org?types=${typeId}`}
        leftIcon={{ name: "menu" }}
        text="Explore All events"
        variant="secondary"
      />
    </div>
  </div>
{/if}
